// src/components/SliderBar.tsx
import React from 'react';
import { Box, Slider } from '@mui/material';

interface SliderBarProps {
  scale: number;
  onScaleChange: (newScale: number) => void;
}

const SliderBar: React.FC<SliderBarProps> = ({ scale, onScaleChange }) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    onScaleChange(newValue as number);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 200 }}>
      <Slider
        value={scale}
        onChange={handleChange}
        aria-labelledby="image-size-slider"
        valueLabelDisplay="auto"
        min={50}
        max={200} // 최대값 200%로 설정
        step={10}
        marks={[
          { value: 50, label: '50%' },
          { value: 100, label: '100%' },
          { value: 150, label: '150%' },
          { value: 200, label: '200%' },
        ]}
      />
    </Box>
  );
};

export default SliderBar;
