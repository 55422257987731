// src/pages/Home.tsx
import React from "react";
import "../styles/auth.css"; // 스타일 임포트


const Home: React.FC = () => {
    return (
        <div className="home-container">
            <h1>환영합니다, PixMon에 오신 것을 환영합니다!</h1>
            {/* 추가적인 홈 페이지 콘텐츠 */}
        </div>
    );
};

export default Home;
