// src/prompts.ts

export const positivePrompts: string[] = [
    "Smooth, seamless blend of product with the background",
    "Perfectly matched tones for a harmonious look",
    "Soft, even lighting that highlights product details",
    "Subtle background contrast to accentuate the product",
    "Warm, cozy vibe that complements the product's texture",
    "Balanced composition for a natural look",
    "Bright and inviting atmosphere with gentle shadows",
    "Natural lighting that enhances product appeal",
    "Product stands out against softly blurred background",
    "Elegant, refined mood with polished visuals",
    "Calm and cohesive look with warm tones",
    "Fresh and clean composition, highlighting product shape",
    "Subdued lighting for a relaxed ambiance",
    "Gentle, warm background that enhances product features",
    "Soft focus on background to bring product forward",
    "High clarity, bringing out product's fine details",
    "Sophisticated lighting, adding depth to the product",
    "Background complements the product without distraction",
    "Sunlit warmth that brings life to the product",
    "Dreamy and warm tones for an inviting look",
    "Even distribution of light for natural composition",
    "Elegant shadows that frame the product nicely",
    "Soft pastels enhancing a gentle, inviting look",
    "Background texture adds a natural feel to the scene",
    "Bright and cheerful lighting complementing the product",
    "Balanced brightness and contrast for a cohesive look",
    "Product integrated naturally into background",
    "Soft gradient background for added elegance",
    "Simple yet polished look, enhancing product appeal",
    "Inviting, sunlit scene with a relaxed tone",
    "Dreamy, soft focus that brings out product's charm",
    "Elegant, muted tones enhancing the product",
    "Subtle lighting, creating a peaceful ambiance",
    "Modern, fresh feel with natural light accents",
    "Soft, diffused shadows for a realistic look",
    "Gently blurred background for product focus",
    "Crisp and clean visuals with minimal noise",
    "Refined, elegant aesthetic that elevates product",
    "Balanced lighting, giving a sense of dimension",
    "Warm and inviting tone for a cozy feel",
    "Classic, timeless ambiance complementing the product",
    "Gentle highlights to make the product shine",
    "Elegant shadows and highlights enhancing shape",
    "Natural and smooth integration of product and background",
    "Refined yet relaxed composition for easy viewing",
    "Polished look with well-blended tones",
    "Serene atmosphere that complements product design",
    "Soft colors enhancing the product's natural beauty",
    "Light and airy atmosphere, emphasizing freshness",
    "Warm sunlight effect that highlights texture",
    "Sophisticated color palette for a timeless feel",
    "Simple background for a polished, clean look",
    "Muted tones enhancing product details beautifully",
    "Natural sunlight for a fresh, lively composition",
    "Rich, elegant tones adding depth to the product",
    "Smooth transitions between product and background",
    "Soft pastel shades for a gentle and warm feel",
    "Balanced and harmonious look with subtle accents",
    "Bright and cheerful atmosphere with clean lines",
    "Elegant shadows for an elevated aesthetic",
    "Polished and refined, complementing product design",
    "Natural, earth tones enhancing product warmth",
    "Soft lighting adding a gentle, calm feel",
    "Refined yet inviting aesthetic for a luxury feel",
    "Clear and vibrant details for a fresh appearance",
    "Warm, welcoming tone with soft focus",
    "Subtle lighting that accentuates product details",
    "Balanced exposure for a well-integrated look",
    "Bright and airy feel that adds lightness",
    "Classic tones for a sophisticated, timeless look",
    "Soft ambient light enhancing product's elegance",
    "Gentle shadows for a smooth, natural feel",
    "Balanced color contrast for a vivid look",
    "Warm and cozy look with harmonious tones",
    "Simple, elegant backdrop for product clarity",
    "Soft, diffused light with gentle shadows",
    "Bright and vibrant tones for lively composition",
    "Subtle glow, enhancing a cozy aesthetic",
    "Elegant, modern look with clean lines",
    "Warm ambiance that adds a touch of luxury",
    "Natural shadows adding depth to product details",
    "Bright and smooth composition with gentle highlights",
    "Soft, inviting look with warm accents",
    "Minimal, modern background enhancing focus on product",
    "Balanced warmth for a refined, natural look",
    "Bright, smooth lighting enhancing product clarity",
    "Warm, earthy tones with a soft, natural feel",
    "Elegant, sleek look with clean transitions",
    "Fresh and vibrant atmosphere with gentle colors",
    "Subtle highlights creating a soft, airy look",
    "Warm tones adding a natural, cozy ambiance",
    "Smooth shadows framing the product gracefully",
    "Elegant, timeless aesthetic with rich tones",
    "Natural, soft lighting that enhances details",
    "Balanced tones for a harmonious visual appeal",
    "Simple, polished look with soft shadows",
    "Elegant, refined backdrop that complements the product",
    "Warm and inviting look with soft focus",
    "Bright atmosphere with a subtle, elegant touch",
    "Softly blurred background with gentle tones"
  ];
  
  export const negativePrompts: string[] = [
    "Overly harsh contrast between product and background",
    "Distracting background elements overpowering the product",
    "Unnatural, exaggerated colors",
    "Shadows too sharp and intense",
    "Overly busy background drawing attention away",
    "Dull and lifeless tone",
    "Blurry, unfocused composition",
    "Uneven lighting, creating harsh shadows",
    "Background elements clashing with the product",
    "Cold, sterile atmosphere lacking warmth",
    "Overexposed highlights",
    "Background feels detached from the product",
    "Distracting color scheme",
    "Dark, oppressive mood",
    "Too much visual noise in the background",
    "Unnatural shadows distorting product shape",
    "Overly bright spots creating an unnatural look",
    "Clashing tones, disrupting visual flow",
    "Overly complex background patterns",
    "Color tones that feel too artificial",
    "Messy and unorganized background",
    "Flat lighting with no depth",
    "Awkward integration of product with background",
    "Excessive saturation of colors",
    "Too many visual elements crowding the frame",
    "Cold, flat lighting that lacks appeal",
    "Chaotic scene detracting from product focus",
    "Distracting reflections on product surface",
    "Dark shadows overpowering the product",
    "Harsh lighting with unnatural contrast",
    "Color tones too cold and harsh",
    "Background competing for viewer's attention",
    "Too many elements in a cramped composition",
    "Harsh, unbalanced lighting",
    "Awkward shadows that look unrealistic",
    "Exaggerated color contrast",
    "Distorted perspective making product look off",
    "Bright glare obscuring product details",
    "Noisy and unclear composition",
    "Uncomfortable, overly sharp details",
    "Background overly dark and uninviting",
    "Artificial lighting that feels unnatural",
    "Unbalanced brightness levels",
    "Background too plain and dull",
    "Shadows too deep and jarring",
    "Unnaturally colored lighting",
    "Harsh, unforgiving tones",
    "Too much clutter in the frame",
    "Disorienting perspective",
    "Unnaturally vibrant background",
    "Dark tones drowning out product features",
    "Overly sharp edges on product or background",
    "Awkward composition with competing elements",
    "Dull color palette with no contrast",
    "Background too busy for a clear view",
    "Distracting textures overpowering product",
    "Unflattering flat lighting",
    "Overly saturated background colors",
    "Harsh shadows breaking up composition",
    "Too much texture making product blend poorly",
    "Background too chaotic",
    "Cold, impersonal mood",
    "Awkward shadows casting on product",
    "Glaring highlights disrupting flow",
    "Overly sharp focus on background elements",
    "Background tones clashing with product color",
    "Distracting visual noise in background",
    "Too many clashing colors",
    "Awkward perspective distorting product view",
    "Blurry background with no clarity",
    "Artificial colors lacking harmony",
    "Harsh shadows that distort details",
    "Background color too similar to product",
    "Overly dim lighting",
    "Bright spots competing with product",
    "Lack of balance in light and shadow",
    "Harsh, cold tones that feel off-putting",
    "Overly complex background design",
    "Uncomfortable color clash",
    "Background too vibrant and overpowering",
    "Flat, lifeless colors",
    "Background feels detached and unnatural",
    "Jarring shadows",
    "Excessive texture that disrupts product",
    "Uncomfortable brightness levels",
    "Awkward color contrast with product",
    "Overly complex background composition",
    "Too much saturation, unnatural feel",
    "Heavy shadow obscuring product",
    "Busy background with cluttered elements",
    "Unappealing color tones lacking harmony",
    "Flat shadows with no depth",
    "Unbalanced focus on background",
    "Overpowering light on background elements",
    "Dull colors blending poorly with product",
    "Distracting sharp edges",
    "Harsh lighting with exaggerated shadows",
    "Background too detailed, losing product focus",
    "Uninviting atmosphere with cold tones",
    "Unbalanced composition with a disjointed feel"
  ];
  