// src/pages/Signup.tsx
import React, { useState } from "react";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import "../styles/auth.css";

const Signup: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();

    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        setError("");
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            if (userCredential.user) {
                await sendEmailVerification(userCredential.user);
                alert("회원가입 성공! 이메일을 확인해주세요.");
                navigate("/login");
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    회원가입
                </Typography>
                {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}

                <form onSubmit={handleSignup} style={{ marginTop: '16px' }}>
                    <Box mb={2}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ py: 1.5 }}>
                        회원가입
                    </Button>
                </form>

                <Box mt={2} textAlign="center">
                    <Typography variant="body2">
                        이미 계정이 있으신가요? <Link to="/login">로그인</Link>
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default Signup;
