// src/pages/Success.tsx
import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Typography, Container } from "@mui/material";
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const Success: React.FC = () => {
    const location = useLocation();
    const { currentUser } = useAuth();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentId = query.get('payment_id');

        if (paymentId && currentUser) {
            // 결제 확인을 위해 백엔드에 요청
            const verifyPayment = async () => {
                try {
                    const token = await currentUser.getIdToken();
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/verify-payment`, { payment_id: paymentId }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.data.status === 'success') {
                        toast.success("결제가 성공적으로 완료되었습니다!");
                    } else if (response.data.status === 'canceled') {
                        toast.error("결제가 취소되었습니다.");
                    } else {
                        toast.warn(`결제 상태: ${response.data.status}`);
                    }
                } catch (err: any) {
                    toast.error("결제 확인에 실패했습니다.");
                }
            };
            verifyPayment();
        } else {
            // Free 구독 성공 시 메시지 표시
            const isFree = location.search.includes("payment_id=");
            if (!isFree) {
                toast.success("Free 구독이 활성화되었습니다!");
            }
        }
    }, [location, currentUser]);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                결제 성공!
            </Typography>
            <Typography variant="body1">
                구독이 성공적으로 완료되었습니다.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/profile" style={{ marginTop: '20px' }}>
                내 프로필 보기
            </Button>
        </Container>
    );
};

export default Success;
