// src/components/SelectedOverlay.tsx
import React from 'react';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SelectedOverlay: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'rgba(255, 255, 255, 0.5)', // 반투명 흰색 오버레이로 변경
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckCircleIcon sx={{ color: 'secondary.main', fontSize: 48 }} />
    </Box>
  );
};

export default SelectedOverlay;
