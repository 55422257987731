// src/components/TabBar.tsx
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CustomTab from './TabsContent/CustomTab';
import ThemesTab from './TabsContent/ThemesTab';
import AssetsTab from './TabsContent/AssetsTab'; // AssetsTab 임포트
import { SelectedImage, BgFile, Folder } from '../types';

interface TabBarProps {
  selectedMenu: string;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  listOfTexts: string[]; // 필요에 따라 사용
  selectedImage: SelectedImage | null;
  setSelectedImage: (image: SelectedImage | null) => void;
  handleThemesImageSelect: (image: SelectedImage | null) => void;
  describeText: string;
  setDescribeText: (text: string) => void;
  dontWantText: string;
  setDontWantText: (text: string) => void;
  selectedCustomImage: string | null;
  setSelectedCustomImage: (url: string | null) => void;
  positivePrompt: string;
  setPositivePrompt: (prompt: string) => void;
  negativePrompt: string;
  setNegativePrompt: (prompt: string) => void;
  onFileListFetched: (fileList: BgFile[]) => void; // 새로운 prop 추가
}

const TabBar: React.FC<TabBarProps> = ({
  selectedMenu,
  selectedTab,
  setSelectedTab,
  // listOfTexts,
  selectedImage,
  setSelectedImage,
  handleThemesImageSelect,
  // describeText,
  setDescribeText,
  // dontWantText,
  setDontWantText,
  selectedCustomImage,
  setSelectedCustomImage,
  positivePrompt,
  setPositivePrompt,
  negativePrompt,
  setNegativePrompt,
  onFileListFetched, // 새로운 prop 수신
}) => {

  const [bgFileList, setBgFileList] = useState<BgFile[]>([]);
  const [userEmail, setUserEmail] = useState<string>('');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };
  // 폴더 관련 상태
  const [folders, setFolders] = useState<Folder[]>([]);
  const [allFolders, setAllFolders] = useState<Folder[]>([]);
  const [currentFolderId, setCurrentFolderId] = useState<string>('root');
  const [loading, setLoading] = useState<boolean>(false);

    // 사용자 이메일을 가져오는 로직 추가 (예: 로그인된 사용자 정보)
    useEffect(() => {
      // 실제 애플리케이션에서는 로그인 로직이나 API 호출을 통해 이메일을 가져와야 합니다.
      const fetchUserEmail = async () => {
        // 예시로 하드코딩된 이메일을 설정
        const email = 'user@example.com';
        setUserEmail(email);
      };
  
      fetchUserEmail();
    }, []);
  
    const handleFileListFetched = (fileList: BgFile[]) => {
      setBgFileList(fileList);
    };
  

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {/* selectedMenu가 'Assets'가 아닐 때만 Tabs 렌더링 */}
      {selectedMenu !== 'Assets' && (
        <Tabs value={selectedTab} onChange={handleChange} orientation="horizontal">
          <Tab label="Custom" value="Custom" />
          <Tab label="Themes" value="Themes" />
          {/* 필요한 경우 다른 탭 추가 */}
        </Tabs>
      )}
      <Box sx={{ p: 3 }}>
        {selectedMenu === 'Assets' ? (
          <AssetsTab
            userEmail={userEmail} // userEmail prop 추가
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setDescribeText={setDescribeText}
            setDontWantText={setDontWantText}
            setSelectedCustomImage={setSelectedCustomImage}
            onFileListFetched={onFileListFetched} // prop 전달
            // folders={folders} // 폴더 목록 전달
            // setFolders={setFolders}
            // allFolders={allFolders}
            // setAllFolders={setAllFolders}
            // currentFolderId={currentFolderId}
            // setCurrentFolderId={setCurrentFolderId}
          />
        ) : (
          <>
            {selectedTab === 'Custom' && (
              <CustomTab
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                positivePrompt={positivePrompt}
                setPositivePrompt={setPositivePrompt}
                negativePrompt={negativePrompt}
                setNegativePrompt={setNegativePrompt}
                setDescribeText={setDescribeText}
                setDontWantText={setDontWantText}
                selectedCustomImage={selectedCustomImage}
                setSelectedCustomImage={setSelectedCustomImage}
              />
            )}
            {selectedTab === 'Themes' && (
              <ThemesTab
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                handleThemesImageSelect={handleThemesImageSelect}
                positivePrompt={positivePrompt}
                setPositivePrompt={setPositivePrompt}
                negativePrompt={negativePrompt}
                setNegativePrompt={setNegativePrompt}
                selectedThemesImage={selectedCustomImage}
                setSelectedThemesImage={setSelectedCustomImage}
              />
            )}
            {/* 필요한 경우 다른 탭 내용 추가 */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TabBar;
