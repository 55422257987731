// src/components/TabsContent/ThemesTab.tsx
import React from 'react';
import {
  Box,
  ImageList,
  ImageListItem,
  Typography, // Typography 추가
} from '@mui/material';
import { useTheme } from '@mui/system';
import SelectedOverlay from '../SelectedOverlay';
import { SelectedImage } from '../../types';

interface ThemesTabProps {
  selectedImage: SelectedImage | null;
  setSelectedImage: (image: SelectedImage | null) => void;
  handleThemesImageSelect: (image: SelectedImage | null) => void;
  positivePrompt: string;
  setPositivePrompt: (prompt: string) => void;
  negativePrompt: string;
  setNegativePrompt: (prompt: string) => void;
  selectedThemesImage: string | null;
  setSelectedThemesImage: (url: string | null) => void;
}

const ThemesTab: React.FC<ThemesTabProps> = ({
  selectedImage,
  setSelectedImage,
  handleThemesImageSelect,
  positivePrompt,
  setPositivePrompt,
  negativePrompt,
  setNegativePrompt,
  selectedThemesImage,
  setSelectedThemesImage,
}) => {
  const theme = useTheme();

  // 이미지 리스트를 문자열 배열로 변경
  const images: string[] = [
    '/images/sample1.png',
    '/images/sample2.png',
    '/images/sample3.png',
    '/images/sample4.png',
    '/images/sample5.png',
    '/images/sample6.png',
    '/images/sample7.png',
    '/images/sample8.png',
    '/images/sample9.png',
    '/images/sample10.png',
    '/images/sample11.png',
    '/images/sample12.png',
    '/images/sample13.png',
    '/images/sample14.png',
    '/images/sample15.png',
    '/images/sample16.png',
    '/images/sample17.png',
    '/images/sample18.png',
    '/images/sample19.png',
    '/images/sample20.png',
    '/images/sample21.png',
    '/images/sample22.png',
    '/images/sample23.png',
    '/images/sample24.png',
    '/images/sample25.png',
    '/images/sample26.png',
    '/images/sample27.png',
    '/images/sample28.png',
    '/images/sample29.png',
    // 필요에 따라 더 많은 이미지 추가
  ];

  // handleSelect 함수 수정: 이미지 URL을 기반으로 선택 상태 관리
  const handleSelect = (img: string) => {
    if (selectedThemesImage === img) {
      setSelectedThemesImage(null); // 선택 해제
      setSelectedImage(null); // 필요 시 추가
      // handleThemesImageSelect(null); // 필요 시 추가
    } else {
      setSelectedThemesImage(img); // 선택
      setPositivePrompt("bright clean easy shiny")
      setNegativePrompt("ugly deformed noisy distorted grainy dark blacklow quality")
      // 필요한 경우 SelectedImage 타입에 맞게 설정
      const selectedImg: SelectedImage = {
        id: img, // ID는 URL로 대체 (필요에 따라 수정)
        url: img,
        source: 'themes', // 예시 소스
      };
      // setSelectedImage(selectedImg);
      // handleThemesImageSelect(selectedImg);
    }
  };

  return (
    <Box>
      {/* 테마 선택 섹션 */}
      <Box sx={{ marginBottom: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Select a theme :
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 2 }}>
              Get photos with similar colors
            </Typography>
          </Box>
        </Box>
        <ImageList cols={2} gap={8}>
          {images.map((img, index) => {
            const isSelected = selectedThemesImage === img;
            return (
              <ImageListItem
                key={index}
                onClick={() => handleSelect(img)}
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                  border: isSelected
                    ? `3px solid ${theme.palette.primary.main}`
                    : '3px solid transparent',
                  borderRadius: 1,
                  overflow: 'hidden',
                  '&:hover': {
                    border: `3px solid ${theme.palette.primary.light}`,
                  },
                }}
              >
                <img
                  src={`${img}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={`Theme ${index + 1}`}
                  loading="lazy"
                  style={{ width: '100%', height: '140px', objectFit: 'cover' }}
                />
                {isSelected && <SelectedOverlay />}
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    </Box>
  );
};

export default ThemesTab;
