// // src/components/Navbar.tsx
// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

// interface NavbarProps {
//   onMenuClick?: () => void;
// }

// const Navbar: React.FC<NavbarProps> = ({ onMenuClick }) => {
//   return (
//     <AppBar position="fixed">
//       <Toolbar>
//         {onMenuClick && (
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={onMenuClick}
//             sx={{ mr: 2, display: { sm: 'none' } }}
//           >
//             <MenuIcon />
//           </IconButton>
//         )}
//         {/* 로고 이미지 대신 텍스트 사용 */}
//         <Typography variant="h4" component="div" sx={{ marginRight: 2 }}>
//           PixMon
//         </Typography>
//         <Box sx={{ flexGrow: 1 }} />
//         <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
//           <Button color="inherit">Home</Button>
//           <Button color="inherit">Photos</Button>
//           <Button color="inherit">Upgrade</Button>
//           <Button color="inherit">Help</Button>
//           <Button color="inherit">My Account</Button>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Navbar;




// src/components/Navbar.tsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";

interface NavbarProps {
  onMenuClick?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onMenuClick }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (err) {
            console.error("로그아웃 실패:", err);
        }
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                {/* 사이드바를 열기 위한 메뉴 버튼 */}
                {onMenuClick && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={onMenuClick}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {/* 로고 및 브랜드 이름 */}
                <Typography variant="h6" component="div" sx={{ marginRight: 2 }}>
                    <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                        PixMon
                    </Link>
                </Typography>

                {/* 오른쪽에 표시될 링크들 */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                    <Button color="inherit" component={Link} to="/Home">Home</Button>
                    <Button color="inherit" component={Link} to="">Blog</Button>
                    <Button color="inherit" component={Link} to="">Coin &nbsp;&nbsp;&nbsp;</Button>

                    {/* 로그인 여부에 따라 다른 버튼 표시 */}
                    {currentUser ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="body1"
                                sx={{
                                    textDecoration: 'underline',
                                }}
                            >
                                Account({currentUser.email})
                            </Typography>
                            <Button color="inherit" onClick={handleLogout}>Logout</Button>
                        </Stack>
                    ) : (
                        <>
                            <Button color="inherit" component={Link} to="/login">Login</Button>
                            <Button color="inherit" component={Link} to="/signup">Signup</Button>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;




