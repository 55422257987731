// src/pages/Login.tsx
import React, { useState } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import "../styles/auth.css";
import axios from 'axios'; // Axios 임포트
import axiosInstance from '../api/axios';


// const response = await axiosInstance.get(
//     `https://121.78.116.47:8000/get_all_folders`,
//     { withCredentials: true }
//   );

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();

    const handleLoginSuccess = async () => {
        try {
            // 현재 사용자의 ID 토큰 획득
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
            const idToken = await user.getIdToken();

            // 백엔드의 /me 엔드포인트 호출
            const response = await axiosInstance.get("/me", {
                // const response = await axiosInstance.get("https://121.78.116.47:8000/me", {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            // 응답이 성공적이면 홈으로 이동
            navigate("/");
        } catch (err: any) {
            console.error("Error during /me request:", err);
            setError("로그인 후 사용자 정보를 불러오는 데 실패했습니다.");
        }
    };

    const handleEmailLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError("");
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            if (userCredential.user) {
                await handleLoginSuccess(); // 로그인 성공 후 처리
            }
        } catch (err: any) {
            console.error("Email login error:", err);
            setError(err.message);
        }
    };

    const handleGoogleLogin = async () => {
        setError("");
        try {
            const result = await signInWithPopup(auth, googleProvider);
            if (result.user) {
                await handleLoginSuccess(); // 로그인 성공 후 처리
            }
        } catch (err: any) {
            console.error("Google login error:", err);
            setError(err.message);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    로그인
                </Typography>
                {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}

                <form onSubmit={handleEmailLogin} style={{ marginTop: '16px' }}>
                    <Box mb={2}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ py: 1.5 }}>
                        로그인
                    </Button>
                </form>

                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleGoogleLogin}
                    sx={{ mt: 2, py: 1.5 }}
                >
                    구글 로그인
                </Button>

                <Box mt={2} textAlign="center">
                    <Typography variant="body2">
                        비밀번호를 잊으셨나요? <Link to="/forgot-password">비밀번호 재설정</Link>
                    </Typography>
                    <Typography variant="body2">
                        계정이 없으신가요? <Link to="/signup">회원가입</Link>
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default Login;
