// src/components/TabsContent/CustomTab.tsx
import React, { useState, useRef } from 'react';
import {
  TextField,
  Button,
  Grid,
  Card,
  CardMedia,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SelectedOverlay from '../SelectedOverlay';
import { SelectedImage } from '../../types';
import { positivePrompts, negativePrompts } from '../../prompts';

interface CustomTabProps {
  selectedImage: SelectedImage | null;
  setSelectedImage: (image: SelectedImage | null) => void;
  setDescribeText: (text: string) => void;
  setDontWantText: (text: string) => void;
  // listOfTexts: string[];
  selectedCustomImage: string | null;
  setSelectedCustomImage: (image: string | null) => void;
  positivePrompt: string;
  setPositivePrompt: (text: string) => void;
  negativePrompt: string;
  setNegativePrompt: (text: string) => void;
}

// 노란색 버튼 스타일 커스터마이즈
const YellowButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFEB3B', // 노란색
  color: '#000000', // 검정색 텍스트
  fontSize: '0.6rem', // 폰트 사이즈 추가 축소
  padding: '4px 8px', // 패딩 줄이기
  minWidth: 'auto', // 최소 너비 제거
  '&:hover': {
    backgroundColor: '#FDD835', // 약간 어두운 노란색으로 호버 효과
  },
}));

// 작은 폰트 사이즈를 가진 굵은 Typography
const SmallBoldTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem', // 더 작은 폰트 사이즈
  fontWeight: 'bold', // 굵은 글씨체
}));

// 작은 폰트 사이즈를 가진 일반 Typography
const SmallTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem', // 더 작은 폰트 사이즈
}));

const CustomTab: React.FC<CustomTabProps> = ({
  selectedImage,
  setSelectedImage,
  setDescribeText,
  setDontWantText,
  // listOfTexts,
  selectedCustomImage,
  setSelectedCustomImage,
  positivePrompt,
  setPositivePrompt,
  negativePrompt,
  setNegativePrompt,
}) => {
  const theme = useTheme();
  const [dontWantList, setDontWantList] = useState<string[]>([]);

  const describeInputRef = useRef<HTMLInputElement>(null);
  const dontWantInputRef = useRef<HTMLInputElement>(null);

  // // 두 개의 랜덤 텍스트를 선택하는 함수
  // const getTwoRandomTexts = () => {
  //   const shuffled = [...listOfTexts].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, 2);
  // };

  // const handleRandom = () => {
  //   const [describe, dontWant] = getTwoRandomTexts();
  //   setPositivePrompt(describe);
  //   setNegativePrompt(dontWant);
  // };

  const handleRandomize = () => {
    const randomPositive = positivePrompts[Math.floor(Math.random() * positivePrompts.length)];
    const randomNegative = negativePrompts[Math.floor(Math.random() * negativePrompts.length)];
    setPositivePrompt(randomPositive);
    setNegativePrompt(randomNegative);
  };

  const handleAddDontWant = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && negativePrompt.trim() !== '') {
      setDontWantList([...dontWantList, negativePrompt.trim()]);
      setNegativePrompt('');
      setDontWantText(dontWantList.join(', ')); // 상위 컴포넌트에 리스트 전달
    }
  };

  const handleDeleteDontWant = (index: number) => {
    const updatedList = dontWantList.filter((_, i) => i !== index);
    setDontWantList(updatedList);
    setDontWantText(updatedList.join(', ')); // 상위 컴포넌트에 업데이트된 리스트 전달
  };

  // "Select a reference" 이미지 목록 (images 폴더의 sample1.jpg ~ sample6.jpg)
  const sampleImages = [
    '/images/sample1.png',
    '/images/sample2.png',
    '/images/sample3.png',
    '/images/sample4.png',
    '/images/sample5.png',
    '/images/sample6.png',
    '/images/sample7.png',
    '/images/sample8.png',
    '/images/sample9.png',
    '/images/sample10.png',
    '/images/sample11.png',
    '/images/sample12.png',
    '/images/sample13.png',
    '/images/sample14.png',
    '/images/sample15.png',
    '/images/sample16.png',
    '/images/sample17.png',
    '/images/sample18.png',
    '/images/sample19.png',
    '/images/sample20.png',
    '/images/sample21.png',
    '/images/sample22.png',
    '/images/sample23.png',
    '/images/sample24.png',
    '/images/sample25.png',
    '/images/sample26.png',
    '/images/sample27.png',
    '/images/sample28.png',
    '/images/sample29.png',
    // 필요에 따라 더 많은 이미지 추가
  ];

  const handleSelect = (img: string) => {
    if (selectedCustomImage === img) {
      setSelectedCustomImage(null);
    } else {
      setSelectedCustomImage(img);
    }
  };

  return (
    <Box>
      {/* 1. Describe your photo */}
      <Box sx={{ marginBottom: 4 }}>
        <SmallBoldTypography variant="h6" gutterBottom>
          Describe your photo :
        </SmallBoldTypography>
        {/* 텍스트와 버튼을 같은 줄에 배치하고 버튼을 오른쪽으로 정렬 */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="body2">Your product is ...</Typography>
          <YellowButton
            variant="contained"
            onClick={handleRandomize}
            endIcon={<AutorenewIcon fontSize="small" />}
          >
            Randomize
          </YellowButton>
        </Box>
        {/* 텍스트필드 입력창을 다음 줄에 배치 */}
        <TextField
          fullWidth
          placeholder="On a wooden floor surrounded by flowers"
          variant="outlined"
          value={positivePrompt}
          onChange={(e) => setPositivePrompt(e.target.value)}
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) =>
            (e.target.placeholder = 'On a wooden floor surrounded by flowers')
          }
          inputRef={describeInputRef}
          InputProps={{
            style: { fontSize: '0.75rem' }, // placeholder 텍스트 크기 조정
          }}
        />
      </Box>

      {/* 2. List what you don't want */}
      <Box sx={{ marginBottom: 4 }}>
        <SmallBoldTypography variant="h6" gutterBottom>
          List what you don't want :
        </SmallBoldTypography>
        <SmallTypography variant="body2" gutterBottom>
          Reduce photos that are/have...
        </SmallTypography>
        <Grid container alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            {/* 비활성화된 버튼 형식으로 표시 */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {dontWantList.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  onDelete={() => handleDeleteDontWant(index)}
                  color="secondary"
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Add things or attributes you don't want"
              variant="outlined"
              value={negativePrompt}
              onChange={(e) => setNegativePrompt(e.target.value)}
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) =>
                (e.target.placeholder =
                  "Add things or attributes you don't want")
              }
              onKeyPress={handleAddDontWant}
              inputRef={dontWantInputRef}
              InputProps={{
                style: { fontSize: '0.75rem' }, // placeholder 텍스트 크기 조정
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* 3. Select a reference */}
      <Box sx={{ marginBottom: 4 }}>
        <SmallBoldTypography variant="h6" gutterBottom>
          Select a reference :
        </SmallBoldTypography>
        <SmallTypography variant="body2" gutterBottom>
          Get photos with similar colors
        </SmallTypography>
        <Grid container spacing={2}>
          {sampleImages.map((img, index) => {
            const isSelected = selectedCustomImage === img;
            return (
              <Grid item xs={6} sm={6} key={index}>
                <Box
                  sx={{
                    position: 'relative', // 오버레이를 위한 상대 위치 설정
                    cursor: 'pointer',
                    border: isSelected
                      ? `3px solid ${theme.palette.primary.main}`
                      : '3px solid transparent',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '&:hover': {
                      border: `3px solid ${theme.palette.primary.light}`,
                    },
                  }}
                  onClick={() => handleSelect(img)}
                >
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={img}
                      alt={`Sample ${index + 1}`}
                      loading="lazy"
                    />
                  </Card>
                  {isSelected && <SelectedOverlay />}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomTab;
