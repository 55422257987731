// src/pages/PaymentRedirect.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography, Button, Container } from '@mui/material';

const PaymentRedirect: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentId = query.get('paymentId');
        const code = query.get('code');
        const message = query.get('message');

        if (code) {
            // 결제 과정에서 오류 발생
            toast.error(message || '결제 과정에서 오류가 발생했습니다.');
            navigate('/subscribe');
        } else if (paymentId) {
            // 결제 성공 시, 서버에 결제 완료를 알립니다.
            fetch(`https://121.78.116.47:8000/payment/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ paymentId }),
            })
            .then(async (response) => {
                if (response.ok) {
                    toast.success('결제가 성공적으로 완료되었습니다!');
                    navigate('/profile');
                } else {
                    const errorData = await response.json();
                    toast.error(errorData.detail || '결제 완료 처리 중 오류가 발생했습니다.');
                    navigate('/subscribe');
                }
            })
            .catch(() => {
                toast.error('결제 완료 처리 중 오류가 발생했습니다.');
                navigate('/subscribe');
            });
        } else {
            toast.error('유효하지 않은 결제 정보입니다.');
            navigate('/subscribe');
        }
    }, [location, navigate]);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                결제 처리 중...
            </Typography>
            <Typography variant="body1">
                잠시만 기다려 주세요.
            </Typography>
        </Container>
    );
};

export default PaymentRedirect;
