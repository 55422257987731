// src/App.tsx
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout";
import ProtectedPage from "./pages/ProtectedPage";
import { FolderProvider } from './contexts/FolderContext';
import Subscribe from './pages/Subscribe';
import Profile from './pages/Profile';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import PaymentRedirect from './pages/PaymentRedirect'; // 추가된 부분



const App: React.FC = () => {
  return (
    <FolderProvider>
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
        />

        <Routes>
          {/* 공개 라우트 */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/payment-redirect" element={<PaymentRedirect />} />

          {/* 보호된 라우트 */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout>
                  <Home />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/protected"
            element={
              <PrivateRoute>
                <Layout>
                  <ProtectedPage />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </>
    </FolderProvider>
  );
};

export default App;
