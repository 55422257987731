// src/components/Layout.tsx
import React, { useState } from 'react';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import TabBar from './TabBar';
import ImageEditor from './ImageEditor';
import { useTheme } from '@mui/material/styles';
import { SelectedImage, BgFile } from '../types';
import { positivePrompts, negativePrompts } from '../prompts';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // 사이드바 메뉴 선택 상태
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState<string>('Create');
  // 탭바 탭 선택 상태
  const [selectedTab, setSelectedTab] = useState<string>('Themes');
  // 모바일 드로어 상태
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  // 이미지 관련 상태
  const [selectedImage, setSelectedImage] = useState<SelectedImage | null>(null);
  const [selectedCustomImage, setSelectedCustomImage] = useState<string | null>(null);

  // Prompt 관련 상태
  const [positivePrompt, setPositivePrompt] = useState<string>('');
  const [negativePrompt, setNegativePrompt] = useState<string>('');

  // 새로운 상태 추가: describeText 및 dontWantText
  const [describeText, setDescribeText] = useState<string>('');
  const [dontWantText, setDontWantText] = useState<string>('');

  // 새로운 상태 추가: fetchedFileList
  const [fetchedFileList, setFetchedFileList] = useState<BgFile[]>([]);
  const [fileList, setFileList] = useState<BgFile[]>([]); // AssetsTab에서 가져온 파일 목록

  const [currentFolderId, setCurrentFolderId] = useState<string>('root');

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
    if (!mobileOpen) {
      // 사이드바를 열 때 기본 메뉴를 활성화
      setSelectedSidebarMenu('Create');
      setSelectedTab('Themes');
    }
  };
  

  // 사이드바 메뉴 클릭 시 호출되는 핸들러
  const handleSidebarMenuClick = (menu: string) => {
    setSelectedSidebarMenu(menu);
    if (menu === 'Create') {
      setSelectedTab('Themes'); // 'Create' 선택 시 기본 탭을 'Themes'로 설정
    }
  };

  // 탭바 탭 변경 시 호출되는 핸들러
  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };



  // ThemesTab에서 이미지 선택 시 랜덤 프롬프트 설정 및 selectedCustomImage 업데이트
  const handleThemesImageSelect = (image: SelectedImage | null) => {
    if (image) {
      setSelectedCustomImage(image.url); // selectedCustomImage 업데이트
      setPositivePrompt(positivePrompts[Math.floor(Math.random() * positivePrompts.length)]);
      setNegativePrompt(negativePrompts[Math.floor(Math.random() * negativePrompts.length)]);
      console.log('Selected Custom Image:', image.url);
    } else {
      setSelectedCustomImage(null);
      setPositivePrompt('');
      setNegativePrompt('');
      console.log('Selected Custom Image cleared');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Navbar onMenuClick={handleDrawerToggle} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          marginTop: '64px', // Navbar의 높이만큼 마진 추가
          flexDirection: isSmallScreen ? 'column' : 'row',
        }}
      >
        {/* 좌측 사이드바 */}
        {isSmallScreen ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // 모바일 성능 향상
            }}
            sx={{
              display: { xs: 'block', sm: 'none' }, // 모바일 환경에서만 드로어 활성화
              '& .MuiDrawer-paper': {
                width: '80px', // 넓은 폭으로 설정하여 메뉴가 한 번에 보이도록 조정
              },
            }}
          >          
            {/* <Sidebar selectedMenu={selectedSidebarMenu} setSelectedMenu={handleSidebarMenuClick} /> */}
            <Sidebar 
              selectedMenu={selectedSidebarMenu} 
              setSelectedMenu={ (menu) => {
                handleSidebarMenuClick(menu);
                handleDrawerToggle();
                }} />
          </Drawer>
        ) : (
          <Box
            sx={{
              width: '80px', // 사이드바 폭 설정 (80px)
              flexShrink: 0,
              backgroundColor: '#f0f0f0',
              transition: 'width 0.3s',
            }}
          >
            <Sidebar selectedMenu={selectedSidebarMenu} setSelectedMenu={handleSidebarMenuClick} />
          </Box>
        )}

        {/* 가운데 탭바 영역 */}
        <Box sx={{ width: '300px', flexShrink: 0, backgroundColor: '#fafafa' }}>
          <TabBar
            selectedMenu={selectedSidebarMenu}
            selectedTab={selectedTab}
            setSelectedTab={handleTabChange}
            listOfTexts={[]} // 필요한 경우 텍스트 목록 추가
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            handleThemesImageSelect={handleThemesImageSelect}
            describeText={describeText} // 추가된 부분
            setDescribeText={setDescribeText} // 추가된 부분
            dontWantText={dontWantText} // 추가된 부분
            setDontWantText={setDontWantText} // 추가된 부분
            selectedCustomImage={selectedCustomImage}
            setSelectedCustomImage={setSelectedCustomImage}
            positivePrompt={positivePrompt}
            setPositivePrompt={setPositivePrompt}
            negativePrompt={negativePrompt}
            setNegativePrompt={setNegativePrompt}
            onFileListFetched={setFetchedFileList} // TabBar로 파일 목록 업데이트 함수 전달
          />
        </Box>

        {/* 오른쪽 이미지 에디터 영역 */}
        <Box
          sx={{
            flex: 1,
            padding: 2,
            overflowY: 'auto',
            backgroundColor: '#ffffff',
          }}
        >
          <ImageEditor
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage} // setSelectedImage 전달
            describeText={describeText}
            dontWantText={dontWantText}
            selectedCustomImage={selectedCustomImage}
            positivePrompt={positivePrompt}
            negativePrompt={negativePrompt}
            selectedTab={selectedTab}
            fileList={fetchedFileList} // 서버에서 받은 파일 목록 전달
            // currentFolderId={currentFolderId} // 현재 폴더 ID 전달
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
