// src/pages/Cancel.tsx
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Typography, Container } from "@mui/material";
import { Link } from 'react-router-dom';

const Cancel: React.FC = () => {
    React.useEffect(() => {
        toast.error("결제가 취소되었습니다.");
    }, []);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                결제 취소
            </Typography>
            <Typography variant="body1">
                결제가 취소되었습니다. 다시 시도해주세요.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/" style={{ marginTop: '20px' }}>
                홈으로 돌아가기
            </Button>
        </Container>
    );
};

export default Cancel;
