// src/api/axios.ts
import axios from "axios";
import { auth } from "../firebase";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // 백엔드 API URL
    withCredentials: true, // 쿠키를 포함하여 요청
});


// 요청 인터셉터를 추가하여 토큰을 헤더에 첨부
axiosInstance.interceptors.request.use(
    async (config) => {
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
